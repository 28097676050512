import React, {useRef, useEffect, useState, CSSProperties} from "react"
import ErrorBoundary from "./ErrorBoundary.tsx"
import * as Sentry from "@sentry/react"
import ResizeObserver from "resize-observer-polyfill"

interface MarqueeProps {
    /** 显示的文本内容 */
    text: string
    /** 移入动画持续时间（秒） */
    moveInDuration?: number
    /** 停留显示时间（秒） */
    stayDuration?: number
    /** 移出动画持续时间（秒） */
    moveOutDuration?: number
    /** 动画关键帧名称（必须唯一） */
    keyframeName: string
    /** 自定义样式 */
    style?: CSSProperties
    /** CSS 类名 */
    className?: string
}

const Marquee: React.FC<MarqueeProps> = ({
    text,
    moveInDuration = 4,
    stayDuration = 6,
    moveOutDuration = 4,
    keyframeName,
    style,
    className
}) => {
    const [childWidth, setChildWidth] = useState<number>(0)
    const [parentWidth, setParentWidth] = useState<number>(0)
    const childRef = useRef<HTMLDivElement | null>(null)
    const parentRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        try {
            if (childRef.current && parentRef.current) {
                const updateWidths = (): void => {
                    const childRect = childRef.current?.getBoundingClientRect()
                    const parentRect = parentRef.current?.getBoundingClientRect()
                    setChildWidth(childRect.width)
                    setParentWidth(parentRect.width)
                }

                updateWidths()

                const resizeObserver = new ResizeObserver(updateWidths)
                resizeObserver.observe(childRef.current)
                resizeObserver.observe(parentRef.current)

                return () => resizeObserver.disconnect()
            }
        } catch (error) {
            // Sentry.captureException(error)
        }
    }, [text])

    const totalDuration = moveInDuration + stayDuration + moveOutDuration
    const moveInPercent = (moveInDuration / totalDuration) * 100
    const stayEndPercent = ((moveInDuration + stayDuration) / totalDuration) * 100

    const haveAnimation = childWidth > parentWidth

    return (
        <div
            ref={parentRef}
            style={{
                ...style,
                width: "100%",
                overflow: "hidden"
            }}
            className={className}
        >
            <style>
                {`
                @keyframes ${keyframeName} {
                    0% { transform: translateX(100%); }
                    ${moveInPercent}% { transform: translateX(0); }
                    ${stayEndPercent}% { transform: translateX(0); }
                    100% { transform: translateX(-100%); }
                }
                `}
            </style>
            <div
                ref={childRef}
                style={{
                    animation: haveAnimation
                        ? `${keyframeName} ${totalDuration}s linear infinite`
                        : undefined,
                    display: "inline-block",
                    whiteSpace: "nowrap"
                }}
            >
                {text}
            </div>
        </div>
    )
}

export default Marquee
