import {useApp, useScreenInfo} from "../components/hooks"
import {useEffect, useRef} from "react"
import {useMount, useSearchParam} from "react-use"
import {UNIQUE_UUID} from "../components/constants"
import {useUuid} from "../components/context"
import {useNavigate} from "react-router-dom"

export default function Home() {
    const {getStorageItem, setStorageItem} = useApp()
    const navigate = useNavigate()
    const {uuid, setUuid} = useUuid()
    const autoEntry = useSearchParam("autoEntry")
    const dontAutoEntry = autoEntry === "0"

    const ref = useRef(null)

    const {query, data, loading} = useScreenInfo()

    useMount(() => {
        getStorageItem(UNIQUE_UUID).then((text) => {
            if (!!text) {
                setUuid(text)
                if (!dontAutoEntry) {
                    navigate("/main")
                }
            }
        })
    })

    const onEntry = () => {
        setStorageItem(UNIQUE_UUID, uuid)
        navigate("/main")
    }

    useEffect(() => {
        if (!!uuid) {
            query({uniqueUuid: uuid})
        }
    }, [uuid])

    useMount(() => {
        ref.current?.focus?.()
    })

    const enable = !!data

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "100vh",
                backgroundColor: "white"
            }}
        >
            <div
                style={{
                    display: "flex",
                    alignItems: "baseline",
                    justifyContent: "center",
                    gap: "40px"
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: "16px"
                    }}
                >
                    <div
                        style={{
                            width: 350,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start"
                        }}
                    >
                        <p style={{marginLeft: "12px", color: "black"}}>大屏标识号：</p>
                        <input
                            type="text"
                            value={uuid}
                            onChange={(e) => setUuid(e.target.value)}
                            ref={ref}
                            style={{
                                height: "40px",
                                padding: "0 12px",
                                border: "1px solid #d1d5db",
                                borderRadius: "4px",
                                width: "100%",
                                color: "black",
                                marginTop: "8px"
                            }}
                            placeholder={"请输入大屏标识号"}
                        />
                        <button
                            disabled={!enable}
                            onClick={onEntry}
                            style={{
                                padding: "8px 16px",
                                color: "white",
                                backgroundColor: enable ? "#3b82f6" : "#9ca3af",
                                borderRadius: "4px",
                                width: "100%",
                                cursor: enable ? "pointer" : "default",
                                marginTop: "8px"
                            }}
                        >
                            进入叫号大屏
                        </button>
                    </div>
                    {loading && (
                        <p style={{color: "#9ca3af", fontSize: "14px", paddingLeft: "12px"}}>
                            查询中...
                        </p>
                    )}
                    {enable && (
                        <div
                            style={{
                                width: 350,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                color: "#9ca3af",
                                fontSize: "14px",
                                paddingLeft: "12px"
                            }}
                        >
                            <p
                                style={{
                                    marginTop: "8px"
                                }}
                            >
                                查询结果：成功
                            </p>
                            <p
                                style={{
                                    marginTop: "8px"
                                }}
                            >
                                机构名称：
                                <span style={{textDecoration: "underline"}}>{data.orgName}</span>
                            </p>
                            <p
                                style={{
                                    marginTop: "8px"
                                }}
                            >
                                大屏名称：
                                <span style={{textDecoration: "underline"}}>{data.screenName}</span>
                            </p>
                        </div>
                    )}
                </div>
                <div
                    style={{
                        width: 350,
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                        justifyContent: "flex-start",
                        color: "#9ca3af",
                        fontSize: "14px",
                        paddingLeft: "12px"
                    }}
                >
                    <p style={{color: "black", marginTop: "12px"}}>提示：</p>
                    <p style={{color: "black"}}>
                        ● 点击 <span style={{fontWeight: "bold"}}>"进入叫号大屏"</span>、
                        <span style={{fontWeight: "bold"}}>"遥控器ok键"</span> 均可进入大屏
                    </p>
                    <p style={{color: "black", marginTop: "2px"}}>
                        ● 进入后的操作，左5连击返回，右5连击刷新。
                    </p>
                </div>
            </div>
        </div>
    )
}
