import {useNavigate} from "react-router-dom"
import {useQuintupleClick, useTvController} from "./hooks.ts"
import React from "react"

export default function ControllerLayer() {
    const navigate = useNavigate()

    //跳回到首页
    const onBack = () => {
        navigate("/?autoEntry=0", {replace: true})
    }

    const onLeft = () => {
        onBack()
    }
    const onRight = () => {
        window.location.reload()
    }

    const {onClick: handleLeft} = useQuintupleClick({
        onQuintupleClick: () => {
            onLeft()
        }
    })
    const {onClick: handleRight} = useQuintupleClick({
        onQuintupleClick: () => {
            onRight()
        }
    })

    const onTop = () => {}
    const onBottom = () => {}

    const {refLeft, refCenter, refRight, refTop, refBottom} = useTvController({
        onLeft: onLeft,
        onRight: onRight,
        onTop: onTop,
        onBottom: onBottom
    })

    return (
        <div className={"absolute left-0 right-0 top-0 bottom-0 flex flex-col"}>
            <div className={"flex flex-1"}>
                <div className={"flex-1"} onClick={handleLeft}></div>
                <div className={"flex-1"} onClick={handleRight}></div>
            </div>
            <div className={"relative opacity-0"}>
                <table>
                    <tbody>
                        <tr>
                            <td></td>
                            <td>
                                <button ref={refTop} className={"p-0 text-sm"}>
                                    上
                                </button>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                                <button ref={refLeft} className={"p-0 text-sm"}>
                                    左
                                </button>
                            </td>
                            <td>
                                <button ref={refCenter} className={"p-0 text-sm"}>
                                    中
                                </button>
                            </td>
                            <td>
                                <button ref={refRight} className={"p-0 text-sm"}>
                                    右
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>
                                <button ref={refBottom} className={"p-0 text-sm"}>
                                    下
                                </button>
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}
