import React, {useEffect, useMemo} from "react"
import Marquee from "./Marquee.tsx"
import {getLastFourChars, protectName} from "./utils.ts"
import {CallNumberRecordScreenDtolist} from "./hooks.ts"
import {useInterval, useNumber} from "react-use"
import {enqueueSnackbar} from "notistack"
import useMeasure from "./useMeasure.ts"

type tProps = {
    data?: CallNumberRecordScreenDtolist[]
}

export default function MainContent(props: tProps) {
    const {data} = props
    const [parentRef, {height: parentHeight}] = useMeasure()
    const [headRef, {height: headHeight}] = useMeasure()
    const [rowRef, {height: rowHeight}] = useMeasure()
    const [number, {inc}] = useNumber(1)

    // 轮播间隔
    useInterval(inc, 10 * 1000)

    // 每页最大行数
    const maxRowPerPage = useMemo(() => {
        if (parentHeight && headHeight && rowHeight) {
            return Math.floor((parentHeight - headHeight) / rowHeight)
        }
        return 0
    }, [parentHeight, headHeight, rowHeight])

    // 总页数
    const pages = useMemo(() => {
        if (maxRowPerPage === 0) {
            return 0
        }
        return Math.ceil(data?.length / maxRowPerPage)
    }, [data, maxRowPerPage])

    // 当前页
    const page = useMemo(() => {
        return number % pages
    }, [pages, number])

    // 当前页数据
    const pageData = useMemo(() => {
        if (data) {
            return data.slice(page * maxRowPerPage, (page + 1) * maxRowPerPage)
        }
        return []
    }, [data, page])

    return (
        <div
            className="flex-1 rounded-tr-md rounded-br-md overflow-hidden mr-1.5 "
            style={{
                backgroundColor: "#0369aa"
            }}
            ref={parentRef}
        >
            <table className={"w-full"}>
                <thead
                    style={{
                        backgroundColor: "#ecf0fb"
                    }}
                    ref={headRef}
                >
                    <tr>
                        <td className={"py-1 text-2xl font-semibold tracking-wider pl-5 "}>医生</td>
                        <td
                            className={"py-1 text-2xl font-semibold tracking-wider text-center"}
                            colSpan={5}
                        >
                            就诊信息
                        </td>
                    </tr>
                </thead>
                <tbody
                    style={{
                        color: "#ecf0fb"
                    }}
                >
                    {pageData?.map((item, i) => {
                        const doctorName = item.doctorName
                        return (
                            <tr style={{borderBottom: "1px solid #095881"}} className={"text-xl"}>
                                <td className={"w-full relative"}>
                                    <Marquee
                                        text={doctorName}
                                        keyframeName={"DOCTOR" + i}
                                        className={
                                            "absolute inset-0 overflow-hidden pl-5 flex items-center"
                                        }
                                        style={{
                                            borderRight: "15px #0369aa solid"
                                        }}
                                    />
                                </td>
                                <td className={"w-0 text-end whitespace-nowrap"}>请</td>
                                <td className={"w-0 whitespace-nowrap px-2"}>
                                    <div
                                        className={"font-bold px-1 w-full text-center"}
                                        style={{
                                            backgroundColor: "#025890",
                                            borderRadius: "3px",
                                            maxWidth: "24vw",
                                            overflow: "hidden"
                                        }}
                                    >
                                        {protectName(item.customerName)} (
                                        {getLastFourChars(item.customerMobile)})
                                        {["MISSED", "WAITING_RESCHEDULED_MISSED"].includes(
                                            item.status
                                        ) && (
                                            <span
                                                className="font-normal"
                                                style={{
                                                    color: "#ef4444"
                                                }}
                                            >
                                                [过号]
                                            </span>
                                        )}
                                    </div>
                                </td>
                                <td className={"w-0 whitespace-nowrap"}>到</td>
                                <td className={"w-0 whitespace-nowrap px-2"}>
                                    <Marquee
                                        text={item.title}
                                        keyframeName={"CLINIC_ROOM" + i}
                                        className={
                                            "font-bold px-1 w-full text-center overflow-hidden"
                                        }
                                        style={{
                                            backgroundColor: "#025890",
                                            borderRadius: "3px",
                                            maxWidth: "18vw"
                                        }}
                                    />
                                </td>
                                <td className={"w-0 whitespace-nowrap pr-5 py-2.5 "}>就诊</td>
                            </tr>
                        )
                    })}
                    <tr className={"text-xl opacity-0"} ref={rowRef}>
                        <td className={"whitespace-nowrap py-2.5"}>高度测量</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}
