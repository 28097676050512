import "resize-observer-polyfill"
import {createRoot} from "react-dom/client"
import App from "./App.tsx"
import "./index.css"
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType
} from "react-router-dom"
import * as Sentry from "@sentry/react"
import {useEffect} from "react"

// Sentry.init({
//     dsn: "https://97d81023ffc4de62d4dc234fbe0d7d65@sentry.wellekq.com/4",
//     integrations: [
//         Sentry.reactRouterV6BrowserTracingIntegration({
//             useEffect,
//             useLocation,
//             useNavigationType,
//             createRoutesFromChildren,
//             matchRoutes
//         }),
//         Sentry.replayIntegration()
//     ],
//     // Tracing
//     tracesSampleRate: 1.0, //  Capture 100% of the transactions
//     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//     tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// })

// async function init() {
//     // 确保 ResizeObserver 可用
//     if (!window.ResizeObserver) {
//         await import("resize-observer-polyfill").then((module) => {
//             window.ResizeObserver = module.default
//         })
//     }
//
//     // 在 ResizeObserver 准备好之后再渲染应用
//     const {createRoot} = await import("react-dom/client")
//     const App = (await import("./App")).default
//
//     const root = createRoot(document.getElementById("root")!)
//     root.render(
//         <>
//             <App />
//         </>
//     )
// }
//
// init().catch(console.error)

const root = createRoot(document.getElementById("root")!)
root.render(
    <>
        <App />
    </>
)
