import React, {useMemo} from "react"
import {getLastFourChars, protectName} from "./utils.ts"
import {CallNumberRecordScreenDtolistWaiting} from "./hooks.ts"
import {useInterval, useNumber} from "react-use"
import useMeasure from "./useMeasure.ts"

type tProps = {
    data: CallNumberRecordScreenDtolistWaiting[]
}

export default function MainWaitList(props: tProps) {
    const {data} = props
    const [parentRef, {height: parentHeight}] = useMeasure()
    const [headRef, {height: headHeight}] = useMeasure()
    const [rowRef, {height: rowHeight}] = useMeasure()
    const [number, {inc}] = useNumber(1)

    // 轮播间隔
    useInterval(inc, 10 * 1000)

    // 每页最大行数
    const maxRowPerPage = useMemo(() => {
        if (parentHeight && headHeight && rowHeight) {
            return Math.floor((parentHeight - headHeight) / rowHeight)
        }
        return 0
    }, [parentHeight, headHeight, rowHeight])

    // 总页数
    const pages = useMemo(() => {
        if (maxRowPerPage === 0) {
            return 0
        }
        return Math.ceil(data?.length / maxRowPerPage)
    }, [data, maxRowPerPage])

    // 当前页
    const page = useMemo(() => {
        return number % pages
    }, [pages, number])

    // 当前页数据
    const pageData = useMemo(() => {
        if (data) {
            return data.slice(page * maxRowPerPage, (page + 1) * maxRowPerPage)
        }
        return []
    }, [data, page])

    return (
        <div
            className="w-1/4 rounded-tl-md rounded-bl-md overflow-hidden"
            style={{
                backgroundColor: "#0369aa"
            }}
            ref={parentRef}
        >
            <table className={"w-full"}>
                <thead
                    ref={headRef}
                    style={{
                        backgroundColor: "#ecf0fb"
                    }}
                >
                    <tr>
                        <td className={"py-1 text-2xl font-semibold tracking-wider text-center"}>
                            等待列表
                        </td>
                    </tr>
                </thead>
                <tbody
                    style={{
                        color: "#ecf0fb"
                    }}
                >
                    {pageData?.map((item) => {
                        return (
                            <tr style={{borderBottom: "1px solid #095881"}} className={"text-xl"}>
                                <td className="py-2.5 flex-1">
                                    <div className={"flex font-normal items-center justify-center"}>
                                        <span className={"whitespace-nowrap"}>
                                            {protectName(item.customerName)} (
                                            {getLastFourChars(item.customerMobile)})
                                            {["MISSED", "WAITING_RESCHEDULED_MISSED"].includes(
                                                item.status
                                            ) ? (
                                                <span
                                                    style={{color: "#ef4444"}}
                                                    className="font-normal"
                                                >
                                                    [过号]
                                                </span>
                                            ) : (
                                                <span className={"pr-1"} />
                                            )}
                                        </span>
                                        {item.typeCode === "RETURN_DIAGNOSIS" ? (
                                            <div
                                                className={
                                                    "rounded-full w-[1.25rem] h-[1.25rem] text-[0.75rem] flex justify-center items-center"
                                                }
                                                style={{
                                                    backgroundColor: "#800080"
                                                }}
                                            >
                                                复
                                            </div>
                                        ) : (
                                            <div
                                                className={
                                                    "rounded-full w-[1.25rem] h-[1.25rem] text-[0.75rem] flex justify-center items-center"
                                                }
                                                style={{
                                                    backgroundColor: "#008000"
                                                }}
                                            >
                                                初
                                            </div>
                                        )}
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                    <tr className={"text-xl"} ref={rowRef}>
                        <td className="py-2.5 flex-1 opacity-0">
                            <div className={"font-normal"}>
                                <span className={"whitespace-nowrap"}>
                                    <span>高度测量</span>
                                </span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}
