import React, {useState, useEffect} from "react"
import {format} from "date-fns"
import {zhCN} from "date-fns/locale"

export function DateShow() {
    const [date, setDate] = useState("")
    const [time, setTime] = useState("")
    const [week, setWeek] = useState("")

    useEffect(() => {
        const intervalId = setInterval(() => {
            const now = new Date()
            const formattedDate = format(now, "yyyy-MM-dd", {locale: zhCN})
            const formattedWeek = format(now, "EEEE", {locale: zhCN})
            const formattedTime = format(now, "HH:mm", {locale: zhCN})
            setDate(formattedDate)
            setWeek(formattedWeek)
            setTime(formattedTime)
        }, 1000)

        return () => clearInterval(intervalId)
    }, [])

    return (
        <div className="flex flex-row items-center text-black">
            <div className="flex flex-col items-end justify-end">
                <span className="text-xs leading-none tracking-wider">{week}</span>
                <span className="text-sm leading-none tracking-wider mt-0.5">{date}</span>
            </div>
            <span className="text-[2.1rem] leading-none ml-2 relative bottom-[-1px]">{time}</span>
        </div>
    )
}
