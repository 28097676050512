import "./App.css"
import {BrowserRouter, Route, Routes} from "react-router-dom"
import Page from "./page"
import Main from "./page/main.tsx"
import React from "react"
import {UuidProvider} from "./components/context.tsx"
import {SnackbarProvider} from "notistack"

function App() {
    return (
        <UuidProvider>
            <SnackbarProvider>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Page />} />
                        <Route path="/main" element={<Main />} />
                    </Routes>
                </BrowserRouter>
            </SnackbarProvider>
        </UuidProvider>
    )
}

export default App
