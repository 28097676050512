import {createContext, useContext, useState, ReactNode} from "react"

type ContextType = {
    uuid: string
    setUuid: (theme: string) => void
}

const UuidContext = createContext<ContextType | undefined>(undefined)

export const UuidProvider: React.FC<{children: ReactNode}> = ({children}) => {
    const [uuid, setUuid] = useState("")

    return <UuidContext.Provider value={{uuid: uuid, setUuid}}>{children}</UuidContext.Provider>
}

export const useUuid = () => {
    const context = useContext(UuidContext)
    if (context === undefined) {
        throw new Error("useTheme must be used within a ThemeProvider")
    }
    return context
}
