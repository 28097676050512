import {SPEECH_TEXT, SET_STORAGE, GET_STORAGE, STORAGE_DATA} from "./constants"
import {useEffectOnce, useInterval, useMount, useSetState, useUnmount} from "react-use"
import React, {useCallback, useEffect, useRef, useState} from "react"
import {enqueueSnackbar} from "notistack"
/**
 * 使用 app 的能力
 */
export function useApp() {
    const sendMessageToReactNative = (message: any) => {
        if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
            window.ReactNativeWebView.postMessage(JSON.stringify(message))
        } else {
            console.warn("ReactNativeWebView is not available")
        }
    }

    // 朗读文字
    const speechText = (t: string) => {
        const content = {
            type: SPEECH_TEXT,
            text: t
        }
        sendMessageToReactNative(content)
    }

    // 保存数据（storage）
    const setStorageItem = (key: string, value: string) => {
        const content = {
            type: SET_STORAGE,
            key: key,
            value: value
        }
        sendMessageToReactNative(content)
    }

    // 获取数据（storage）
    const getStorageItem = (key: string): Promise<string | null> => {
        return new Promise((resolve) => {
            const requestId = Date.now().toString()
            const content = {
                type: GET_STORAGE,
                key: key,
                requestId: requestId
            }

            const handleMessage = (event: MessageEvent) => {
                try {
                    const data = JSON.parse(event.data)
                    if (data.type === STORAGE_DATA && data.requestId === requestId) {
                        window.removeEventListener("message", handleMessage)
                        resolve(data.value)
                    }
                } catch {}
            }

            window.addEventListener("message", handleMessage)
            sendMessageToReactNative(content)
        })
    }

    return {
        speechText,
        setStorageItem,
        getStorageItem
    }
}

/**
 * 心跳包留存状态
 */
function useHeartbeat(interval: number) {
    const [isConnected, setIsConnected] = useState(false)
    const [lastTimeStamp, setLastTimeStamp] = useState(Date.now())
    useInterval(() => {
        if (Date.now() > lastTimeStamp + interval) {
            setIsConnected(false)
        }
    }, 1000)
    const heartbeat = () => {
        setIsConnected(true)
        setLastTimeStamp(Date.now())
    }
    return {heartbeat, isConnected}
}

/**
 * SSE 状态管理
 */
export function useSseNumberQueue({
    onMessage,
    onRetry,
    uuid
}: {
    onMessage?: (message: any) => void
    onRetry?: () => void
    uuid: string
}) {
    const ref = useRef<EventSource>()
    const {isConnected, heartbeat} = useHeartbeat(10 * 2000)
    const [lastMessage, setLastMessage] = useState<any>(null)

    const logger = (type: string, message: any) => {
        fetch("/his-war/call-number/callNumberScreen/callNumberScreenLoadingLog", {
            method: "POST",
            headers: {
                "Content-Type": "application/json" // 设置请求头，告诉服务器请求体的格式为 JSON
            },
            body: JSON.stringify({
                uniqueUuid: uuid,
                loadType: type,
                message: JSON.stringify(message)
            })
        })
    }

    const createEventSource = (): void => {
        ref.current?.close?.() //清除上一次的连接

        const newEventSource = new EventSource(
            `/his-war/call-number/callNumberScreen/sse?uuid=${uuid}`
        )

        newEventSource.onmessage = (event: MessageEvent) => {
            const newMessage = JSON.parse(event.data)
            if (newMessage.type === "keep-heartbeat") {
                heartbeat()
            } else {
                setLastMessage(newMessage)
                onMessage?.(newMessage)
            }
        }

        newEventSource.onopen = () => {
            heartbeat()
            onRetry()
            logger("on", "sse连接已建立")
        }

        newEventSource.onerror = (err: Event) => {
            logger("error-off", "sse连接已断开:" + JSON.stringify(err))
        }

        ref.current = newEventSource
    }

    // 第一次进入页面时，创建连接
    useMount(() => {
        createEventSource()
    })

    // 断开连接时，重连
    useInterval(
        () => {
            logger("re-connect", "尝试重连")
            createEventSource()
        },
        !isConnected ? 10 * 1000 : null
    )

    // 组件卸载时，关闭连接
    useUnmount(() => {
        ref.current?.close?.()
    })

    return {
        isConnected: isConnected,
        lastMessage: lastMessage
    }
}

export type tScreenInfo = {
    id: number
    orgName: string
    screenName: string
    uniqueUuid: string
    clinicLogoUrl: string
}

export function useScreenInfo() {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState<tScreenInfo | null>(null)

    // 查询大屏标识号详情
    const query = (data: any) => {
        setLoading(true)
        return fetch("/his-war/call-number/callNumberScreen/getCallNumberScreen", {
            method: "POST",
            headers: {
                "Content-Type": "application/json" // 设置请求头，告诉服务器请求体的格式为 JSON
            },
            body: JSON.stringify(data) // 将 JavaScript 对象转换为 JSON 字符串
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok " + response.statusText)
                }
                return response.json() // 将响应体解析为 JSON
            })
            .then((json) => {
                if (json.flag === 1) {
                    return json.data
                }
                throw new Error(`查询失败`)
            })
            .then((data) => {
                setData(data)
                return data
            })
            .catch(() => {
                setData(null)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return {query, loading, data}
}

export interface Data {
    callNumberRecordScreenDTOList: CallNumberRecordScreenDtolist[]
    callNumberRecordScreenDTOListWaiting: CallNumberRecordScreenDtolistWaiting[]
    warmPrompt: string
}

export interface CallNumberRecordScreenDtolist {
    id: number
    orgId: number
    callNumberDay: any
    callNumberRecordId: number
    callNumberClinicId: number
    reservationId: any
    customerId: number
    doctorId: any
    doctorName: string
    customerName: string
    customerMobile: string
    showFlag: any
    creater: any
    companyId: any
    status: string
    title: string
}
export interface CallNumberRecordScreenDtolistWaiting {
    id: number
    orgId: number
    callNumberDay: string
    callNumberRecordId: any
    callNumberClinicId: any
    reservationId: number
    customerId: number
    doctorId: number
    doctorName: string
    customerName: string
    customerMobile: string
    showFlag: any
    updateTime: any
    createTime: string
    typeCode: string
    creater: any
    companyId: number
    status: any
    title: any
}

export function useMainData(uuid: string) {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState<Data | null>(null)

    // 查询
    const query = () => {
        setLoading(true)
        return fetch("/his-war/call-number/callNumberScreen/getCallNumberScreenWaitingList", {
            method: "POST",
            headers: {
                "Content-Type": "application/json" // 设置请求头，告诉服务器请求体的格式为 JSON
            },
            body: JSON.stringify({
                uniqueUuid: uuid
            }) // 将 JavaScript 对象转换为 JSON 字符串
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok " + response.statusText)
                }
                return response.json() // 将响应体解析为 JSON
            })
            .then((json) => {
                if (json.flag === 1) {
                    // enqueueSnackbar("uuid:" + uuid)
                    // enqueueSnackbar(JSON.stringify(json.data))
                    return json.data
                }
                throw new Error(`查询失败`)
            })
            .then((data) => {
                setData(data)
            })
            .catch(() => {
                setData(null)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return {query, loading, data}
}

/**
 * 5连击事件
 */
interface UseQuintupleClickOptions {
    onQuintupleClick: () => void
    clickInterval?: number
}

export const useQuintupleClick = ({
    onQuintupleClick,
    clickInterval = 300
}: UseQuintupleClickOptions) => {
    const hit = 5 //连击数
    const clickCount = useRef(0)
    const lastClickTime = useRef(0)

    const handleClick = useCallback(() => {
        const currentTime = new Date().getTime()
        if (currentTime - lastClickTime.current > clickInterval) {
            clickCount.current = 1
        } else {
            clickCount.current += 1
        }

        lastClickTime.current = currentTime

        if (clickCount.current === hit) {
            onQuintupleClick()
            clickCount.current = 0
        }
    }, [onQuintupleClick, clickInterval])

    useEffect(() => {
        return () => {
            clickCount.current = 0
            lastClickTime.current = 0
        }
    }, [])

    return {onClick: handleClick}
}

/**
 * 电视遥控器
 * 实现方法：
 *  传入4个回调函数，分别对应左、右、上、下按钮的点击事件
 *  传出5个按钮的ref，分别对应左、右、中、上、下，
 *  当遥控器点击方向键时，对应方向的button ref会获得焦点，
 *  每次方向按钮获取焦点时，会将焦点重新聚焦到refCenter上，并为该方向增加计数，当计数超过5时触发方向键回调事件
 *  重置计数的时间间隔为500ms，当超过500ms没有点击方向键时，计数会被重置
 */
type UseTvController = {
    onLeft: () => void
    onRight: () => void
    onTop: () => void
    onBottom: () => void
}
type Direction = "left" | "right" | "top" | "bottom"

export const useTvController = (params: UseTvController) => {
    const {onLeft, onRight, onTop, onBottom} = params

    // 创建ref
    const refLeft = useRef<HTMLButtonElement>(null)
    const refRight = useRef<HTMLButtonElement>(null)
    const refCenter = useRef<HTMLButtonElement>(null)
    const refTop = useRef<HTMLButtonElement>(null)
    const refBottom = useRef<HTMLButtonElement>(null)

    // 记录每个方向的点击次数
    const directionCount = useRef({
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
    })

    // 记录最后一次点击的时间
    const lastClickTime = useRef<Record<Direction, number>>({
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
    })

    // 重置指定方向的计数
    const resetCount = useCallback((direction: Direction) => {
        directionCount.current[direction] = 0
        lastClickTime.current[direction] = 0
    }, [])

    // 重置所有方向的计数
    const resetAllCounts = useCallback(() => {
        Object.keys(directionCount.current).forEach((direction) => {
            resetCount(direction as Direction)
        })
    }, [resetCount])

    // 处理方向focus
    const handleDirectionFocus = useCallback(
        (direction: Direction, callback: () => void) => {
            const currentTime = new Date().getTime()
            const lastTime = lastClickTime.current[direction]

            // 如果超过500ms没有focus，重置计数
            if (currentTime - lastTime > 500) {
                directionCount.current[direction] = 1
            } else {
                directionCount.current[direction] += 1
            }

            lastClickTime.current[direction] = currentTime

            // 将焦点重新聚焦到中间
            setTimeout(() => {
                refCenter.current?.focus()
            }, 0)

            // 当focus次数达到5次时，触发回调并重置计数
            if (directionCount.current[direction] >= 5) {
                callback()
                resetCount(direction)
            }
        },
        [resetCount]
    )

    // 设置focus事件监听
    useEffect(() => {
        const leftElement = refLeft.current
        const rightElement = refRight.current
        const topElement = refTop.current
        const bottomElement = refBottom.current

        const handleLeft = () => handleDirectionFocus("left", onLeft)
        const handleRight = () => handleDirectionFocus("right", onRight)
        const handleTop = () => handleDirectionFocus("top", onTop)
        const handleBottom = () => handleDirectionFocus("bottom", onBottom)

        leftElement?.addEventListener("focus", handleLeft)
        rightElement?.addEventListener("focus", handleRight)
        topElement?.addEventListener("focus", handleTop)
        bottomElement?.addEventListener("focus", handleBottom)

        return () => {
            leftElement?.removeEventListener("focus", handleLeft)
            rightElement?.removeEventListener("focus", handleRight)
            topElement?.removeEventListener("focus", handleTop)
            bottomElement?.removeEventListener("focus", handleBottom)
            resetAllCounts()
        }
    }, [handleDirectionFocus, onLeft, onRight, onTop, onBottom, resetAllCounts])

    // 初始化时将焦点设置到center
    useEffect(() => {
        refCenter.current?.focus()
    }, [])

    return {
        refLeft,
        refRight,
        refCenter,
        refTop,
        refBottom
    }
}
