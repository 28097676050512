import React, {useEffect} from "react"
import {DateShow} from "../components/DateShow.tsx"
import {useUuid} from "../components/context.tsx"
import {useApp, useMainData, useScreenInfo, useSseNumberQueue} from "../components/hooks.ts"
import {useInterval, useMount, useQueue} from "react-use"
import {getLastFourChars, protectName} from "../components/utils.ts"
import {useNavigate} from "react-router-dom"
import Marquee from "../components/Marquee.tsx"
import ControllerLayer from "../components/ControllerLayer.tsx"
import MainContent from "../components/MainContent.tsx"
import MainWaitList from "../components/MainWaitList.tsx"

type tMessage = {
    customerName: string
    customerNameKs: string
    doctorName: string
    messageType: string
    mobile: string
    title: string
}

export default function Main() {
    const {uuid} = useUuid()
    const navigate = useNavigate()
    const {speechText} = useApp()

    const {query: queryScreenInfo, data: screenInfo, loading: screenInfoLoading} = useScreenInfo()
    const {query: queryMainData, data: mainData, loading: mainDataLoading} = useMainData(uuid)

    const warmPrompt = mainData?.warmPrompt ?? "请勿喧哗，根据就诊叫号依次排队就诊。"

    //播报队列
    const {add, remove, first} = useQueue<tMessage>()

    const {isConnected} = useSseNumberQueue({
        uuid: uuid,
        onMessage: (message) => {
            if (message?.messageType === "2") {
                queryMainData()
            }
            if (message?.messageType === "3") {
                try {
                    const data: tMessage = JSON.parse(message.messageContent)
                    //播放2次
                    Array(2)
                        .fill(null)
                        .forEach(() =>
                            add({
                                ...data
                            })
                        )
                } catch (e) {
                    console.error("message序列化失败：" + e)
                } finally {
                    queryMainData()
                }
            }
            if (message?.messageType === "4") {
                window.location.reload()
            }
        },
        onRetry: () => {
            queryMainData() //sse重连前,先刷新一下
        }
    })

    useMount(() => {
        if (!uuid) {
            navigate("/", {replace: true})
        } else {
            queryMainData()
            queryScreenInfo({uniqueUuid: uuid})
        }
    })

    useInterval(
        () => {
            remove()
        },
        !!first ? 10 * 1000 : null
    )

    useEffect(() => {
        if (!!first) {
            speechText(
                `请手机尾号${getLastFourChars(first.mobile).split("").join(" ")},${first.customerName.split("").join(" ")},到 ${first.title}就诊`
            )
        }
    }, [first])

    useEffect(() => {
        // 保存原始的字体大小
        const originalFontSize = document.documentElement.style.fontSize

        // 设置新的字体大小
        document.documentElement.style.fontSize = "2.1vw" // 例如，放大到20px

        // 在组件卸载时恢复原来的字体大小
        return () => {
            document.documentElement.style.fontSize = originalFontSize
        }
    }, [])

    // return <div>333</div>

    return (
        <div
            className="flex flex-col h-screen overflow-hidden"
            style={{backgroundColor: "#01375e", color: "#01375e"}}
        >
            <div>
                <div
                    className="flex justify-between items-center"
                    style={{
                        background: `#ecf0fb`,
                        height: "2.9rem"
                    }}
                >
                    <div className={"flex-1 h-full"}>
                        <img
                            src={screenInfo?.clinicLogoUrl}
                            alt=""
                            style={{
                                height: "100%",
                                maxWidth: "80%"
                            }}
                        />
                    </div>
                    <div className="text-3xl font-bold tracking-widest cursor-pointer text-center">
                        {screenInfo?.screenName}
                    </div>
                    <div className={"flex justify-end items-center pr-2 flex-1"}>
                        <DateShow />
                    </div>
                </div>
            </div>
            <div className="flex flex-1 py-1.5 overflow-hidden">
                <MainContent data={mainData?.callNumberRecordScreenDTOList} />
                <MainWaitList data={mainData?.callNumberRecordScreenDTOListWaiting} />
            </div>
            <div
                className={`flex justify-between items-center `}
                style={{
                    background: `#ffe590`
                }}
            >
                <div
                    className={"px-5 py-2 text-lg"}
                    style={{
                        backgroundColor: "#ffd23d"
                    }}
                >
                    温馨提示:
                </div>
                <Marquee
                    text={warmPrompt}
                    keyframeName={"WARM-TIPS"}
                    className="px-2 py-2 text-lg flex-1 tracking-wider relative overflow-hidden"
                    moveInDuration={6}
                    stayDuration={8}
                    moveOutDuration={6}
                />
            </div>
            {!!first && (
                <div className="absolute inset-0 flex items-center justify-center pointer-events-none left-0 right-0 top-0 bottom-0">
                    <div
                        className="font-bold tracking-widest p-10 rounded-lg shadow-lg flex-col justify-center items-center gap-2"
                        style={{
                            fontSize: "12vmin",
                            backgroundColor: "white",
                            color: "#01375e"
                        }}
                    >
                        <div
                            style={{
                                textAlign: "center"
                            }}
                        >
                            {protectName(first.customerName)} ({first.mobile})
                        </div>
                        <div style={{marginTop: "2vmin", textAlign: "center"}}>{first.title}</div>
                    </div>
                </div>
            )}
            <div
                style={{
                    position: "fixed",
                    top: "0.2rem",
                    right: "0.2rem",
                    width: "0.3rem",
                    height: "0.3rem",
                    borderRadius: "1rem",
                    backgroundColor: isConnected ? "#32CD32" : "#FF4500"
                }}
            >
                {/*状态点*/}
            </div>
            {/*控制*/}
            {import.meta.env.MODE !== "development" && <ControllerLayer />}
        </div>
    )
}
