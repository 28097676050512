/**
 * 姓名保护（单星号处理）
 */
export function protectName(name: string): string {
    // 移除首尾空格
    name = name.trim()

    // 处理空字符串
    if (name.length === 0) {
        return ""
    }

    // 使用更全面的中文字符范围
    const chineseRegex = /[\u2E80-\u2FD5\u3190-\u319f\u3400-\u4DBF\u4E00-\u9FCC\uF900-\uFAAD]/
    const hasChinese = chineseRegex.test(name)

    if (hasChinese) {
        // 中文名字处理
        return protectChineseName(name)
    } else {
        // 非中文名字处理
        return protectNonChineseName(name)
    }
}

function protectChineseName(name: string): string {
    if (name.length === 1) {
        return "*"
    } else if (name.length === 2) {
        return name[0] + "*"
    } else {
        return name[0] + "*" + name[name.length - 1]
    }
}

function protectNonChineseName(name: string): string {
    if (name.length <= 1) {
        return "*"
    } else {
        return name[0] + "*" + name[name.length - 1]
    }
}
export function getLastFourChars(input: string | null | undefined): string {
    if (input === null || input === undefined) {
        return ""
    }

    const length = input.length
    return length <= 4 ? input : input.slice(-4)
}
